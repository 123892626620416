import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Home from './templates/home-template';
import About from './templates/about-template';
import Services from './templates/services-template';
import News from './templates/news-template';
import Footer from './templates/footer-template';
import Contact from './templates/contact-template';
import Claims from './templates/claims-template';
import Error from './templates/error-template';
import SingleNews from './templates/single-news-template';
import SearchTemplate from './templates/search-template';
import CookieBanner from "./components/CookieBanner";

import "./App.css";
import Header from "./components/Header";

import { ContentfulContext } from "./contexts/context";

class App extends Component {
  static contextType = ContentfulContext;

  render() {
    let pathname = window.location.pathname;
    let isEnglish = pathname.includes("/en") ? "/en/" : "/";

    return (
      <BrowserRouter>
        <Header />
        <Route
          render={({ location }) => {
            return (
              <Switch location={location}>
                <Route
                  exact
                  path={`${isEnglish}contact/`}
                  component={Contact}
                />
                ;
                <Route exact path={`${isEnglish}claims`} component={Claims} />;
                <Route
                  exact
                  path={`${isEnglish}services`}
                  component={Services}
                />
                ;
                <Route exact path={`${isEnglish}about`} component={About} />;
                <Route exact path={`${isEnglish}news`} component={News} />;
                <Route
                  exact
                  path={`${isEnglish}news/:slug`}
                  component={SingleNews}
                />
                ;
                <Route exact path={`${isEnglish}home`} component={Home} />;
                <Route exact path={`${isEnglish}`} component={Home} />;
                <Route
                  exact
                  path={`${isEnglish}search/`}
                  component={SearchTemplate}
                />
                <Route component={Error} />;
              </Switch>
            );
          }}
        />
        <Footer />
        <CookieBanner
          description="
            Raiffeisen Insurance Broker përdor “cookies” për ta bërë faqen e internetit sa më miqësore. 
            Ju mund te perzgjedhni nese deshironi qe te perdorim “cookies” gjate shfletimit tuaj ne ueb faqen tone. 
            Për detaje dhe udhëzime për ç’aktivizim, kliko "
          link="'Më shumë informacione'"
          href="/Cookies_Wesbite_RIBK_Shqip.pdf"
        />
      </BrowserRouter>
    );
  }
}

export default App;
