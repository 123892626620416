import { Component } from "react";
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';

import MarkerImage from '../../images/pin.png';

const Marker = ({ image }) => {
    return <div><img src={ image } alt="Marker"></img></div>;
}

class Map extends Component {
    render() {
        const { mapKey, zoom, center, children } = this.props;

        return(
            <div className="rfb-map__wrapper">
                <GoogleMapReact
                bootstrapURLKeys={{ key: mapKey }}
                defaultCenter={center}
                zoom={zoom}
                >
                {
                    children.map(({fields}, index) => {
                        return <Marker key={ index } 
                                lat={ fields.title }
                                lng={ fields.subTitle }
                                image={ MarkerImage }
                            ></Marker>
                    })
                }
                </GoogleMapReact>
            </div>
        )
    }
}

Map.propTypes = {
    mapKey: PropTypes.string,
    zoom: PropTypes.number,
    center: PropTypes.object
}

Map.defaultProps = {
    mapKey: null,
    zoom: 11,
    center: {
        lat: 42.651960,
        lng: 21.146204
    }
};

export default Map;