import { TimelineMax, Sine } from 'gsap';

export const tweenMaxAnimateOnce = (target, duration) => {
    const gsapTimelineElements = target.querySelectorAll('.gsap-timeline');
  
    setTimeout(() => {
      if(gsapTimelineElements && gsapTimelineElements.length > 0) {
        const gsapTimline = new TimelineMax();

        gsapTimelineElements.forEach((gElement) => {
            gsapTimline.fromTo(gElement, 0.3, { opacity: 0, x: 0, y: 20, scaleY: 1.1, skewY: 1, ease: Sine.easeIn, delay: -0.05 }, 
                { opacity: 1, x: 0, y: 0, scaleY: 1, skewY: 0, ease: Sine.easeOut, delay: -0.05 });
            gElement.classList.add('gsap-active');
        });
      }
    }, duration);
}

export const loadImage = (element) => {
  const imageElements = element.querySelectorAll('picture');

  if(!imageElements && imageElements.length > 0) {
    return;
  }

  imageElements.forEach(function(image){
    const pictureElements = image.childNodes;
    const imageInstance = new Image();
    pictureElements.forEach(element => {
        imageInstance.src = element.attributes[0].value;
    });
    imageInstance.alt = image.getAttribute('data-alt');

    image.append(imageInstance);
  })
}