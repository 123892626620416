import React, { Component } from "react";
import FTI from "../components/FTI/FTI";
import Tabs from "../components/Tabs";
import { Helmet } from 'react-helmet';
import { SITE_TITLE } from '../constants/Config';
import { ContentfulContext } from '../contexts/context';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';


class ServicesTemplate extends Component {
    constructor(props) {
      super(props);
      this.state = {
          slug: this.props.match.url
      }
    }
    componentDidMount () {
        const hashId = window.location.hash.slice(1)
        const elem = document.getElementById(hashId)
        if (elem) {
            elem.scrollIntoView()
        }
    }

    static contextType = ContentfulContext;

    render() {
        
        const { getSinglePage } = this.context;
        const value = getSinglePage(this.state.slug);
        const ftiData = value.sections[0].fields;
        const tabsData = value.sections[1].fields;
        const ftiButtonData = value.sections[2].fields;

        return(
            <React.Fragment>
                <Helmet>
                    <title>{ `${ SITE_TITLE } | ${ value?.page }` }</title>
                    <link rel="canonical" href={ window.location.href }></link>
                    <meta name="description" content={ value?.seoDescription } />
                    <meta property="og:title" content={ `${ SITE_TITLE } | ${ value?.page }` } />
                    <meta property="og:description" content={ value?.seoDescription } />
                    <meta property="og:url" content={ window.location.href } />
                    <meta property="og:image" content={ value?.seoImage.fields.file.url } />
                    <meta property="og:image:secure_url" content={ value?.seoImage.fields.file.url } />
                    <meta property="og:image:width" content="1820" />
                    <meta property="og:image:height" content="984" />                          
                </Helmet>
                <FTI primary leftLine image={ ftiData.image.fields.file.url }
                    subtitle={ ftiData.subTitle }>{ ftiData.title }</FTI>
                <Tabs data={ tabsData.items } buttonTitle={ tabsData.buttonTitle } buttonLink={ tabsData.buttonLink }>{ documentToReactComponents(tabsData.description) }</Tabs>
                <FTI  image={ ftiButtonData.image.fields.file.url }
                     buttonTitle={ ftiButtonData.buttonTitle } buttonUrl={ftiButtonData.buttonLink}>{ ftiButtonData.title }</FTI>
            </React.Fragment>
        )
    }
}

export default ServicesTemplate;