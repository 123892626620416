import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Swiper from 'swiper/swiper-bundle';
import Observer from '@researchgate/react-intersection-observer';
import { tweenMaxAnimateOnce, loadImage } from '../../utility/timeline';

import '../../swiper.min.css';

import TAIFSlider from '../TAIFSlider';

const handleIntersection = (event, unobserve) => {
    if(event.isIntersecting) {
        tweenMaxAnimateOnce(event.target)
        loadImage(event.target);
        unobserve();
    }
}

class TAIF extends Component {
    componentDidMount() {
        new Swiper('.swiper-container-home-slider', {
            effect: 'fade',
            pagination: {
              el: '.swiper-pagination-home-slider',
              clickable: true,
            },
            controller: {
              inverse: true,
            }
        });
    }
    render() {
        const options = {
            onChange: handleIntersection,
            rootMargin: '0% 0% -25%'
        };
        const { data } = this.props;
        
        return(
            <React.Fragment>
                <Observer {...options}>
                    <div className="rfb-taif swiper-container swiper-container-home-slider rfb-taif-window-height-slider rfb-taif-slider--min-height">
                        <div className="swiper-wrapper">
                            {
                                data.map(({fields}, index) => {
                                    return <TAIFSlider key={ index }
                                            buttonTitle={fields.buttonTitle}
                                            buttonLink={fields.buttonLink}
                                            image={ fields.image.fields.file.url }
                                            forms={ fields.items[0] }
                                            >{ fields.title }</TAIFSlider>
                                })
                            }                    
                        </div>
                        <div className="swiper-pagination swiper-pagination-home-slider rfb-taif-pagination-home-slider rfb-flex rfb-flex--items-center rfb-flex--justify-center"></div>
                    </div>
                </Observer>
            </React.Fragment>
        );
    }
}

TAIF.propTypes = {
    data: PropTypes.array,
}

TAIF.defaultProps = {
    data: null
};

export default TAIF;