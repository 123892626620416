import { Component } from 'react';
import PropTypes from 'prop-types';
import Observer from '@researchgate/react-intersection-observer';
import { tweenMaxAnimateOnce, loadImage } from '../../utility/timeline';
import { Link } from 'react-router-dom';
import Card from '../Card/Card';

const handleIntersection = (event, unobserve) => {
    if(event.isIntersecting) {
        tweenMaxAnimateOnce(event.target)
        loadImage(event.target);
        unobserve();
    }
}
class Cards extends Component { 
    render() {
        const options = {
            onChange: handleIntersection,
            rootMargin: '0% 0% -25%'
        };
        
        const { buttonTitle, buttonLink , data, children } = this.props;
        
        return(
            <Observer {...options}>
                <div className="rfb-cards rfb-comp rfb-comp--padding rfb-pt-85 rfb-comp--dark">
                    <div className="rfb-container">
                        <div className="rfb-row">
                            {
                                (buttonTitle) ? 
                                    <div className="rfb-col rfb-col-lg-6 col-md-8 rfb-col-sm-12 gsap-timeline">
                                        <div className="rfb-max-w-80 rfb-max-w-lg-100 rfb-pr-16 rfb-max-w-md-100 rfb-p-md-0">
                                            <h3 className="rfb-cards__title rfb-font-primary-bold">{ children }</h3>
                                        </div>
                                    </div>
                                    :   <div className="rfb-col rfb-col-lg-8 rfb-col-sm-12 gsap-timeline">
                                            <div className="rfb-max-w-80 rfb-max-w-lg-100 rfb-pr-14 rfb-max-w-md-100 rfb-p-md-0">
                                                <h3 className="rfb-cards__title rfb-font-primary-bold">{ children }</h3>
                                            </div>
                                        </div>
                            }
                            {
                                (buttonTitle) ?
                                    <div className="rfb-col rfb-col-lg-6 rfb-col-sm-12 rfb-cards__button gsap-timeline">
                                        <div className="rfb-window-height rfb-flex rfb-flex--items-center rfb-flex--justify-end rfb-flex-md--justify-center">
                                            <Link to={ buttonLink } className="rfb-button rfb-button--primary rfb-font-primary-bold rfb-mt-md-40">{ buttonTitle }</Link>
                                        </div>
                                    </div>
                                    : null
                            }
                            <div className="rfb-col rfb-col-lg-12 rfb-col-sm-12">
                                <div className="rfb-flex rfb-flex--wrap rfb-mt-100 rfb-mt-md-40">
                                    {
                                        data.map(({fields, sys}, index) => {
                                            return <Card key={ index }
                                                    url={fields.slug}
                                                    image={ fields.image.fields.file.url }>
                                                        { fields.title }
                                                    </Card>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Observer>
        )
    }
}

Cards.propTypes = {
    data: PropTypes.array.isRequired,
    button: PropTypes.bool,
    buttonTitle: PropTypes.string,
    buttonLink: PropTypes.string
}

Cards.defaultProps = {
    data: null,
    button: false,
    buttonTitle: null,
    buttonLink: null
};  

export default Cards;