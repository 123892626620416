import React, { Component } from "react";
import {Helmet} from "react-helmet";

import Cards from '../components/Cards';
import TAIB from '../components/TAIB';
import TAIC from '../components/TAIC';
import SliderCards from '../components/SliderCards';
import { ContentfulContext } from '../contexts/context';
import { SITE_TITLE } from '../constants/Config'; 
import TAIF from "../components/TAIF";

import ImageProcess from '../utility/ImageProcess';


class HomeTemplate extends Component {
    constructor(props) {
      super(props);
      this.state = {
          slug: this.props.match.url
      }
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.slug !== this.props.slug) {
            this.setState({ slug: nextProps.slug })
        }
    }

    static contextType = ContentfulContext;

    render() {
        const { getSinglePage, getBlogPosts } = this.context;
        const value = getSinglePage(this.state.slug);
        const blogs = getBlogPosts();
        const sliderDetail = value.sections[0].fields;
        const taicDetail = value.sections[1].fields;
        const sliderCardsDetail = value.sections[2].fields;
        const cardsDetail = value.sections[3].fields;
        const taibDetail = value.sections[4].fields;

        return(
            <React.Fragment>
              <Helmet>
                <title>{ `${ SITE_TITLE } | ${ value.page }` }</title>
                <link rel="canonical" href={ window.location.href }></link>
                <meta name="description" content={ value.seoDescription } />
                <meta property="og:title" content={ `${ SITE_TITLE } | ${ value.page }` } />
                <meta property="og:description" content={ value.seoDescription } />
                <meta property="og:url" content={ window.location.href } />
                <meta property="og:image" content={ value.seoImage.fields.file.url } />
                <meta property="og:image:secure_url" content={ value.seoImage.fields.file.url } />
                <meta property="og:image:width" content="1820" />
                <meta property="og:image:height" content="984" />            
              </Helmet>    
              <TAIF data={ sliderDetail.items }></TAIF>
              <TAIC data={ taicDetail.items }
                    image={ ImageProcess(taicDetail.image, 1680, 865, 50) }>
                { taicDetail.title }
              </TAIC>
              <SliderCards data={ sliderCardsDetail.items } tabTitle={sliderCardsDetail.title}>{ sliderCardsDetail.title }</SliderCards>
              <Cards data={ blogs } buttonTitle={ cardsDetail.buttonTitle } buttonLink={ cardsDetail.buttonLink }>{ cardsDetail.title }</Cards>
              <TAIB buttonTitle={ taibDetail.buttonTitle } 
                    buttonLink={ taibDetail.buttonLink } 
                    image={ ImageProcess(taibDetail.image, 1680, 865, 50) } 
                    description={ taibDetail.subTitle }>
                    { taibDetail.title }</TAIB>
            </React.Fragment>
        )
    }
}

export default HomeTemplate;