import React from "react";
import { Component } from 'react';
import Observer from '@researchgate/react-intersection-observer';
import { tweenMaxAnimateOnce, loadImage } from '../../utility/timeline';
import PropTypes from 'prop-types';
import Contact from '../Contact';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const handleIntersection = (event, unobserve) => {
    if(event.isIntersecting) {
        tweenMaxAnimateOnce(event.target)
        loadImage(event.target);
        unobserve();
    }
}

class Form extends Component {
    constructor(props) {
        super(props);

        const recaptchaRef = React.createRef();
        const showMessage = {
            message: null,
            errorClass: null
        }

        this.state = {
            price_and_selected_fields: '',
            name: '',
            lastName: '',
            email: '',
            phone_number: '',
            message: '',
            captcha: recaptchaRef,
            loading: false,
            showMessage: showMessage
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        loadReCaptcha("6LdLMx4iAAAAADlG49PNJZaCx6QcrYJGHmlBOaXe");
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    }
    verifyCallback = (recaptchaToken) => {
        this.setState({
            captcha: recaptchaToken 
        })
    }

    htmlDecode = (input) => {
        var e = document.createElement('textarea');
        e.innerHTML = input;
        // handle case of empty input
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }

    handleSubmit(event) {
        const postData = this.state;
        const request = new XMLHttpRequest();
        let boolPathname = window.location.pathname.includes('/en') ? true : false;

        const responseMessages = {
            success: (boolPathname) ? 'Email sent successfully.' : 'Emaili u dërgua me sukses.',
            error:  (boolPathname) ? 'Email could not be sent.' : 'Emaili nuk u dergua.',
        }
        request.onreadystatechange = () => {
            if (request.readyState === 4) {
                try {
                    const response = JSON.parse(request.response);

                    this.state.showMessage.message = (response.success) ? responseMessages['success'] : responseMessages['error'];
                    this.state.showMessage.errorClass = (response.success) ? 'success' : 'error';

                    this.setState({
                        loading: false,
                        showMessage: this.state.showMessage
                    })
                }catch (e) {
                    this.state.showMessage.message = responseMessages['error'];
                    this.state.showMessage.errorClass = 'error';

                    this.setState({
                        loading: false,
                        showMessage: this.state.showMessage
                    })
                }
            }
        };
        this.setState({
            loading: true
        })
        request.open('POST', 'https://raiffeisenbroker-kosovo.com/frm-contact/sendMail.php', true);
        request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
        request.send(JSON.stringify(postData));
        event.preventDefault();
    }

    render() {
        const options = {
            onChange: handleIntersection,
            rootMargin: '0% 0% -25%'
        };
        const { formTitle, data, checkboxTitle, checkboxLabel, image } = this.props;
        const params = window.location.search.substring(1);
        const paramsList = params.replace(/&/g, "\n");
        this.state.price_and_selected_fields = decodeURIComponent(paramsList).replace(/\+/g, " ");
        this.state.price_and_selected_fields = this.state.price_and_selected_fields.replace('SigurimiKasko_price=', 'Price: ');
        const strings = { 'SigurimiKasko_price': 'SigurimiKasko: \nPrice: ', 'SigurimiKasko_sel=': 'Selected Item: ', 'Sigurim i Pronës_item=': 'Selected Item: ', 'Sigurim i Pronës_price=': 'Sigurim i Pronës: \nPrice: ', 'Sigurim i jetës së kredimarrësit_item=': 'Selected Item: ', 'Sigurim i jetës së kredimarrësit_price=': 'Sigurim i jetës së kredimarrësit: \nPrice: ' };

        for (var key in strings) {
            if (!strings.hasOwnProperty(key)) {
                continue;
            }
            this.state.price_and_selected_fields = this.state.price_and_selected_fields.replace(new RegExp(key, "g"), strings[key]);
        }
        const isEnglish = window.location.pathname.includes('/en') ? true : false ;
        const ifContact = !!window.location.pathname.includes('/contact');
        
        return (
            <Observer {...options}>
                <div className="rfb-contact rfb-claims rfb-comp rfb-comp--padding rfb-pt-85 rfb-pb-135 rfb-comp--dark" style={{ backgroundImage: `url(${image})` }}>
                    <div className="rfb-container">
                        <div className={(ifContact)? `rfb-d-none` : `rfb-row`}>
                            <div className="rfb-col rfb-col-lg-8 rfb-col-sm-12">
                                <div className="rfb-p-md-0 rfb-mb-90">
                                    <h3 className="rfb-cards__title rfb-font-primary-bold gsap-timeline">{formTitle}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="rfb-row">
                            <div className="rfb-col rfb-col-lg-6 rfb-col-md-6 rfb-col-sm-12">
                                <div className="rfb-pr-100 rfb-pr-sm-0 rfb-form  gsap-timeline">
                                    <form method="POST" onSubmit={this.handleSubmit}>
                                        <textarea value={ this.state.price_and_selected_fields } onChange={this.handleInputChange} name="price_and_selected_fields" className="rfb-w-100 rfb-mb-48 rfb-font-primary-medium rfb-pb-16 rfb-form__item" hidden></textarea>
                                        <input value={ this.state.name } required maxLength="50" onChange={this.handleInputChange} name="name"  type="text" placeholder={(isEnglish) ? `Name` : `Emri`} className="rfb-w-100 rfb-mb-48 rfb-font-primary-medium rfb-pb-16 rfb-form__item" />
                                        <input value={ this.state.lastName } required maxLength="50" onChange={this.handleInputChange} name="lastName" type="text" placeholder={(isEnglish) ? `Last Name` :`Mbiemri`} className="rfb-w-100 rfb-mb-48 rfb-font-primary-medium rfb-pb-16 rfb-form__item" />
                                        <input value={ this.state.email } required maxLength="50" onChange={this.handleInputChange} name="email" type="email" placeholder={(isEnglish) ? `Email address` : `Email Adresa` } className="rfb-w-100 rfb-mb-48 rfb-font-primary-medium rfb-pb-16 rfb-form__item" />
                                        <input value={ this.state.phone_number } required maxLength="50" onChange={this.handleInputChange} name="phone_number" type="text" placeholder={(isEnglish) ? `Phone number` : `Numri i telefonit` } className="rfb-w-100 rfb-mb-48 rfb-font-primary-medium rfb-pb-16 rfb-form__item"/>
                                        <textarea value={ this.state.message } required maxLength="200" onChange={this.handleInputChange} name="message" id="message" placeholder={(isEnglish) ? `Message` : `Mesazhi`} className="rfb-w-100 rfb-font-primary-medium rfb-pb-24 rfb-mb-64 rfb-form__item rfb-form__item--textarea"></textarea>
                                        <p className="rfb-mb-18 rfb-font-primary-medium">{ checkboxTitle }</p>
                                        <div className="rfb-flex rfb-flex--items-start rfb-form__subscribe">
                                            <input name="subscribe" id="subscribe" type="checkbox" value="Subscribe" className="rfb-form__item rfb-form__item--checkbox" />
                                            <label htmlFor="subscribe" className="rfb-font-primary-medium rfb-ml-24 rfb-form__subscribe-label">{ checkboxLabel }</label>    
                                        </div>
                                        <div className="block-recaptcha">
                                            <ReCaptcha
                                                sitekey='6LdLMx4iAAAAADlG49PNJZaCx6QcrYJGHmlBOaXe'
                                                action='action_name'
                                                verifyCallback={this.verifyCallback}
                                            />
                                        </div>
                                        <input type="submit" value={(isEnglish) ? `Submit` : `Dërgo`} className={`rfb-mt-40 rfb-button rfb-button--primary rfb-button--extra-large rfb-font-primary-bold rfb-form__item rfb-form__item--submit ${(this.state.loading) ? 'loading' : 'not'}`} />
                                        <p className={ `rfb-showMessage ${this.state.showMessage.errorClass}` }>{ this.state.showMessage.message }</p>
                                    </form>
                                </div>
                            </div>
                            <div className="rfb-col rfb-col-lg-6 rfb-col-md-6 rfb-col-sm-12 gsap-timeline">
                                <div className="rfb-flex rfb-flex--direction-column rfb-flex--justify-center rfb-pt-44 rfb-pb-44 rfb-pl-42 rfb-pr-42 rfb-p-sm-25 rfb-mt-sm-64 rfb-bg--black">
                                    {
                                    data.map(({ fields }, index) => {
                                        return <Contact key={index} image={ fields.image.fields.file.url } description={ documentToReactComponents(fields.description) }>{ fields.title }</Contact>
                                    }) 
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Observer>
        );
    }
}

Form.propTypes = {
    formTitle: PropTypes.string,
    data: PropTypes.string,
    checkboxTitle: PropTypes.string,
    checkboxLabel: PropTypes.string
}

Form.defaultProps = {
    formTitle: null,
    data: null,
    checkboxTitle: null,
    checkboxLabel: null
};

export default Form;