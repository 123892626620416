import { Component } from 'react';
import PropTypes from 'prop-types';
import Statistic from '../Statistic';
import Observer from '@researchgate/react-intersection-observer';
import { tweenMaxAnimateOnce, loadImage } from '../../utility/timeline';
import * as THREE from "three";
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

import NoClouds from '../../images/earth-images/2_no_clouds_4k.jpg';
import ElevBump from '../../images/earth-images/elev_bump_4k.jpg';
import Water from '../../images/earth-images/water_4k.png';
import FairCloud from '../../images/earth-images/fair_clouds_4k.png';

const handleIntersection = (event, unobserve) => {
    if(event.isIntersecting) {
        tweenMaxAnimateOnce(event.target)
        loadImage(event.target);
        unobserve();
    }
}

gsap.registerPlugin(ScrollTrigger); 

class TAIC extends Component { 
    componentDidMount() {
        const width = window.innerWidth;
        const height = this.mount.clientHeight;

        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(45, width / height, 0.01, 1000);
        this.camera.position.z = 1.5;
        this.camera.position.x = -0.5;
        // this.camera.position.y = 0.4;
        
        this.renderer = new THREE.WebGLRenderer({ antialias: true });
        this.renderer.setSize(width, height);
        this.mount.appendChild(this.renderer.domElement);     
        
        this.scene.add(new THREE.AmbientLight(0x333333));

        let light = new THREE.DirectionalLight(0xffffff, 1);
        light.position.set(5,3,5);
        this.scene.add(light);

        const textureLoader = new THREE.TextureLoader();
        textureLoader.crossOrigin = '';
        this.cloudTexture = textureLoader.load(NoClouds);
        this.elevBump = textureLoader.load(ElevBump);
        this.water = textureLoader.load(Water);

        const earth = new THREE.SphereGeometry(0.5, 32, 32);
        const earthMaterial = new THREE.MeshPhongMaterial({ 
            map: this.cloudTexture,
            bumpMap: this.elevBump,
            bumpScale: 0.005,
            specularMap: this.water,
            shininess: 40
        });
        this.sphere = new THREE.Mesh( earth, earthMaterial );
        this.sphere.rotation.y = 6;

        this.scene.add( this.sphere );

        this.fairCloud = textureLoader.load(FairCloud);
        const cloud = new THREE.SphereGeometry(0.5 + 0.003, 32, 32);
        const cloudMaterial = new THREE.MeshPhongMaterial({
            map: this.fairCloud,
            transparent: true,
            shininess: 40
        });
        this.clouds = new THREE.Mesh( cloud, cloudMaterial );
        this.scene.add(this.clouds);

        window.addEventListener('resize', this.onWindowResize, false);
        
        this.animate();

        gsap.to(this.mount, {
            scrollTrigger: {
                trigger: this.mount,
                onUpdate: (self) => {
                    this.direction = self.direction;
                    const progress = self.progress / 120;
                    if (this.direction === 1) {
                        this.sphere.rotation.y += progress;
                        this.clouds.rotation.y += progress;
                    }else {
                        this.sphere.rotation.y -= progress;
                        this.clouds.rotation.y -= progress;
                    }
                }
            }
        });
    }

    animate = () => {
        if (this.direction === 1) {
            this.sphere.rotation.y += 0.0004;
            this.clouds.rotation.y += 0.0004;
        }else {
            this.sphere.rotation.y -= 0.0004;
            this.clouds.rotation.y -= 0.0004;
        }
        this.renderer.render(this.scene, this.camera);
        requestAnimationFrame(this.animate);
    }

    onWindowResize = () => {
        if (this.mount) {
            this.camera.aspect = window.innerWidth / this.mount.clientHeight;
            this.camera.updateProjectionMatrix();
            this.renderer.setSize(window.innerWidth, this.mount.clientHeight)
        }
    }

    render() {
        const options = {
            onChange: handleIntersection,
            rootMargin: '0% 0% -25%'
        };
        
        const { data, children } = this.props;
        
        return(
            <Observer {...options}>
                <div className="rfb-taic rfb-comp rfb-comp--dark rfb-comp--padding">
                    <div className="three-holder gsap-timeline" ref={mount => { this.mount = mount}}></div>
                    <div className="rfb-container">
                        <h2 className="rfb-taic__title rfb-title-primary rfb-font-primary-bold rfb-color-white rfb-max-w-80 rfb-ls-16-negative gsap-timeline">
                        { children }
                        </h2>
                        <div className="rfb-flex">
                            {
                            data.map(({ fields }, index) => {
                                return <Statistic key={index} title={ fields.subTitle }>{ fields.title }</Statistic>
                            }) 
                            }
                        </div>
                    </div>
                </div>
            </Observer>
        )
    }
}

TAIC.propTypes = {
    image: PropTypes.string,
    data: PropTypes.array,
}

TAIC.defaultProps = {
    image: null,
    data: null
};

export default TAIC;