import { Component } from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Expandable from "../Expandable/Expandable";
import Observer from '@researchgate/react-intersection-observer';
import { tweenMaxAnimateOnce, loadImage } from '../../utility/timeline';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const handleIntersection = (event, unobserve) => {
    if(event.isIntersecting) {
        tweenMaxAnimateOnce(event.target)
        loadImage(event.target);
        unobserve();
    }
}

class Tabs extends Component {
    render() {
        const options = {
            onChange: handleIntersection,
            rootMargin: '0% 0% -25%'
        };

        const { data, buttonTitle, buttonLink, children } = this.props;

        const isServices = !!window.location.pathname.includes('/services');

        return(
            <Observer {...options}>
                <div className="rfb-expandable rfb-comp rfb-comp--dark rfb-comp--padding" id="rfb-expandable">
                    <div className="rfb-container">
                        <div className="rfb-max-w-100 rfb-m-auto">
                            <h2 className={(isServices) ? "rfb-expandable-title rfb-expandable-title-small rfb-font-primary-bold gsap-timeline" : "rfb-expandable-title rfb-font-primary-bold gsap-timeline"}>{ children }</h2>
                            {
                                data.map(({fields}, index) => {
                                    return <input key={ ++index } className={`rfb-tabs__checkbox rfb-${fields.title}`} id={`tab-${ ++index }`} type="radio" name="tabs" defaultChecked={ (index === 1) }/>
                                })
                            }                        
                            <div className="rfb-tabs__buttons rfb-flex rfb-flex--items-center rfb-flex--justify-left rfb-mb-80 rfb-mb-sm-32" id="rfb-tabs__buttons">
                            {
                                data.map(({fields}, index) => {
                                    const pl30Class = classNames({'rfb-pl-30': (++index > 1)});
                                    return <label htmlFor={`tab-${ index }`} key={ index } className={`rfb-tabs__item rfb-expandable-tabs__item rfb-relative ${pl30Class} rfb-pr-30 rfb-font-primary-medium gsap-timeline`}>
                                            { fields.title }</label>
                                })
                            }
                            </div>
                            <div className="rfb-tabs__content">
                            {
                                data.map(({ fields }, index) => {
                                    return  <div key={ index } className="rfb-tabs__content__tab rfb-flex--direction-column gsap-timeline">
                                                {
                                                    fields.items.map(({fields}, itemIndex) => {
                                                        return <Expandable image={ fields.image.fields.file.url } key={ itemIndex } subTitle={fields.subTitle} buttonTitle={ buttonTitle } buttonLink={ buttonLink } desc={ documentToReactComponents(fields.description) }>{ fields.title }</Expandable>
                                                    })
                                                }
                                            </div>
                                })
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </Observer>
        )
    }
}

Tabs.propTypes = {
    tabsData: PropTypes.array,
    buttonTitle: PropTypes.array,
    buttonLink: PropTypes.array
}

Tabs.defaultProps = {
    tabsData: null,
    buttonTitle: null,
    buttonLink: null
};

export default Tabs;