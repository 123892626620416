export const SITE_TITLE = 'Raiffeisen Brokers';
export const PREFIX = "rfb";
export const GOOGLE_MAP_API_KEY = 'AIzaSyAFxwS4hmBjasYgMSFysvDOADkauun-O24';
export const GOOGLE_MAP_CENTER = {
    lat: 42.651960,
    lng: 21.146204
}

//Contentful keys
export const CONTENTFULSPACEID = 'hcas4ivg1vwg';
export const CONTENTFULACCESSTOKEN = 'K2lewdtfgF3xyHK4czwlxv9lcgHDrMn-rIhaGl0H6Q0';