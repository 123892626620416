import React, { Component } from 'react'
import FTI from '../components/FTI';
import { Parallax as ParallaxBG } from "react-parallax";
import { Helmet } from 'react-helmet';
import { SITE_TITLE } from '../constants/Config';
import { ContentfulContext } from '../contexts/context';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Redirect } from 'react-router-dom';

import FTIFacebook from '../images/news-single-facebook.svg';
import FTITwiter from '../images/news-single-twitter.svg';
import Moment from 'react-moment';

class SingleNewsTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slug: this.props.match.url
        }
    }
    componentDidMount () {
        const hashId = window.location.hash.slice(1)
        const elem = document.getElementById(hashId)
        if (elem) {
            elem.scrollIntoView()
        } else {
            window.scrollTo(0, 0);
        }
    }
    componentWillReceiveProps(nextProps){	
        if (nextProps.location.pathname !== this.props.location.pathname) {
            this.setState({ slug: nextProps.location.pathname })
        }	
    }

    static contextType = ContentfulContext;

    render() {
        const { getSinglePost, getSinglePage } = this.context;
        const value = getSinglePost(this.state.slug.replace(/\/news\//g,''));
        const news = getSinglePage("news");
        if(!value) {
            return <Redirect to="/page-not-found"/>;
        }
        
        const ftiDetails = value.fields.sections[0].fields;
        
        let currentURL = window.location.href;

        const options = {
            renderNode: {
                'embedded-asset-block': (node) => {
                    const imgURL = node.data.target.fields.file.url;
                    return <picture class="rfb-mb-32 rfb-d-block"><img class="rfb-news-single__content__img" src={imgURL} alt="content img" /></picture>
                }
            }
          };

        return(
            <React.Fragment>
                <Helmet>
                    <title>{ `${ SITE_TITLE } | ${ value?.page }` }</title>
                    <link rel="canonical" href={ window.location.href }></link>
                    <meta name="description" content={ value?.seoDescription } />
                    <meta property="og:title" content={ `${ SITE_TITLE } | ${ value?.page }` } />
                    <meta property="og:description" content={ value?.seoDescription } />
                    <meta property="og:url" content={ window.location.href } />
                    <meta property="og:image" content={ news?.seoImage.fields.file.url } />
                    <meta property="og:image:secure_url" content={ news?.seoImage.fields.file.url } />
                    <meta property="og:image:width" content="1820" />
                    <meta property="og:image:height" content="984" />                       
                </Helmet>
                <div className="rfb-news-single rfb-comp rfb-comp--dark rfb-comp--padding rfb-pt-85">
                    <div className="rfb-container rfb-news-single-container-pr">
                        <div className="rfb-max-w-100">
                            <h1 className="rfb-news-single-title rfb-font-primary-bold rfb-mb-24 rfb-ls-16-negative">{ value.fields.title }</h1>
                        </div>
                        <div className="rfb-row rfb-mb-32 rfb-mb-sm-16">
                            <p className="rgb-col-lg-4 rfb-col-md-5 rfb-col-sm-4 rfb-col-4 rfb-news-single-paragraph rfb-news-single-date rfb-title-quote rfb-flex rfb-flex--items-center rfb-font-primary-medium">
                                <Moment format="D MMMM, YYYY" withTitle>
                                    { value.sys.createdAt }
                                </Moment>
                            </p>
                            <div className="rgb-col-lg-8 rfb-col-md-7 rfb-col-sm-8 rfb-col-8 rfb-news-single__social rfb-flex rfb-flex--justify-end rfb-flex--items-center">
                                <p className="rfb-text-uppercase rfb-news-single__social-title rfb-mr-32 rfb-title-quote">share</p>
                                <a href={`https://www.facebook.com/sharer.php?u=${currentURL}`} target="_blank" rel="noreferrer" className="rfb-news-single__social__icon rfb-flex rfb-flex--items-center rfb-mr-32">
                                    <picture className="rfb-picture-cover">
                                        <source srcSet={ FTIFacebook }/>
                                        <img className="rfb-news-single__social__icon__img rfb-mr-16 rfb-mr-sm-10" loading="lazy" src={ FTIFacebook } alt="news-single-facebook" />
                                    </picture>
                                    <span className="rfb-news-single__social--text font-primary-medium rfb-title-quote rfb-text-capitalize">Facebook</span>
                                </a>
                                <a href={`https://twitter.com/share?url=${currentURL}&text=${value.fields.title}&media=${value.fields.image.fields.file.url}`} target="_blank" rel="noreferrer" className="rfb-news-single__social__icon rfb-flex rfb-flex--items-center rfb-mr-32">
                                    <picture className="rfb-picture-cover">
                                        <source srcSet={ FTITwiter }/>
                                        <img className="rfb-news-single__social__icon__img rfb-mr-16 rfb-mr-sm-10" loading="lazy" src={ FTITwiter } alt="news-single-facebook" />
                                    </picture>
                                    <span className="rfb-news-single__social--text font-primary-medium rfb-title-quote rfb-text-capitalize">Twitter</span>
                                </a>
                            </div>
                        </div>
                    
                        <div className="rfb-news-single-border-b rfb-mb-20"></div>
                        <div className="rfb-d-inline-block rfb-mb-80 rfb-mb-sm-48 rfb-w-100">
                            <div className="rfb-picture-cover rfb-aspect-ratio-16x9 rfb-d-inline-block">
                                <ParallaxBG bgImage={ value.fields.image.fields.file.url } strength={150} blur={{ min: 3, max: -5 }}></ParallaxBG>
                            </div>
                        </div>
                        <div className="rfb-news-single__content rfb-d-inline-block rfb-pb-80 rfb-pb-md-70">
                            { documentToReactComponents(value.fields.content, options) }
                        </div>
                    </div>
                </div>
                <FTI linkTitle={ ftiDetails.buttonTitle } buttonUrl={ ftiDetails.buttonLink } image={ ftiDetails.image.fields.file.url }>{ ftiDetails.title }</FTI>
            </React.Fragment>
        );
    }
}

export default SingleNewsTemplate;