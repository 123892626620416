import { Component } from 'react';
import PropTypes from 'prop-types';
import Observer from '@researchgate/react-intersection-observer';
import { tweenMaxAnimateOnce, loadImage } from '../../utility/timeline';

const handleIntersection = (event, unobserve) => {
    if(event.isIntersecting) {
        tweenMaxAnimateOnce(event.target)
        loadImage(event.target);
        unobserve();
    }
}
class Contact extends Component { 
    render() {
        const { description, image, children } = this.props;

        const options = {
            onChange: handleIntersection,
            rootMargin: '0% 0% -25%'
        };

        return(
            <Observer {...options}>
                <div className="rfb-flex rfb-mb-64 rfb-mb-sm-32">
                    <figure className="rfb-contact__icon rfb-flex--none">
                        <img className="rfb-flex--none rfb-w-100" loading="lazy" src={ image } alt="" />
                    </figure>
                    <p className="rfb-ml-32 rfb-ml-sm-16 rfb-form__contact-text rfb-color-white">
                        <span className="rfb-font-primary-medium rfb-d-block">{ children }</span>{ description }
                    </p>
                </div>
            </Observer>
        );
    }
}

Contact.propTypes = {
    description: PropTypes.string,
    image: PropTypes
}

Contact.defaultProps = {
    description: null,
    image: null
};

export default Contact;