import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import { SITE_TITLE } from '../constants/Config';
import { ContentfulContext } from '../contexts/context';

import Cards from '../components/Cards';
import FTI from "../components/FTI/FTI";


class NewsTemplate extends Component {
    constructor(props) {
      super(props);
      this.state = {
          slug: this.props.match.url
      }
    }
    componentWillReceiveProps(nextProps){	
        if (nextProps.slug !== this.props.slug) {	
            this.setState({ slug: nextProps.slug })	
        }	
    }

    static contextType = ContentfulContext;

    render() {
        const { getSinglePage, getBlogPosts } = this.context;	
        const value = getSinglePage(this.state.slug);	
        const blogs = getBlogPosts();	
        const cardsDetail = value.sections[0].fields;	
        const ftiDetail = value.sections[1].fields;

        return(
            <React.Fragment>
                <Helmet>
                    <title>{ `${ SITE_TITLE } | ${ value?.page }` }</title>
                    <link rel="canonical" href={ window.location.href }></link>
                    <meta name="description" content={ value?.seoDescription } />
                    <meta property="og:title" content={ `${ SITE_TITLE } | ${ value?.page }` } />
                    <meta property="og:description" content={ value?.seoDescription } />
                    <meta property="og:url" content={ window.location.href } />
                    <meta property="og:image" content={ value?.seoImage.fields.file.url } />
                    <meta property="og:image:secure_url" content={ value?.seoImage.fields.file.url } />
                    <meta property="og:image:width" content="1820" />
                    <meta property="og:image:height" content="984" />                              
                </Helmet>
                <Cards data={ blogs }>{ cardsDetail.title }</Cards>	
                <FTI image={ ftiDetail.image.fields.file.url }	
                linkTitle={ ftiDetail.buttonTitle } linkUrl={ ftiDetail.buttonLink }>{ ftiDetail.title }</FTI>
            </React.Fragment>
        )
    }
}

export default NewsTemplate;