import React, { Component } from "react";
import client from '../Contentful';

const ContentfulContext = React.createContext();

class ContentfulProvider extends Component {
  state = {
    contentfulItems: [],
    blogItems: [],
    boardMembers: [],
    loading: true,
    mounted: false,
    locale: null,
  };

  async getData() {
    try {
      let pathname = window.location.pathname;
      const lang = pathname.includes("/en") ? "en-US" : "sq-AL";
      let response = await client.getEntries({
        content_type: "page",
        include: 10,
        locale: lang,
      });

      let blogResponse = await client.getEntries({
        content_type: "singleNews",
        include: 10,
        locale: lang,
      });

      let aboutUsResponse = await client.getEntries({
        content_type: "boardMember",
        include: 10,
        locale: lang,
      });

      let contentfulItems = response?.items;
      let blogItems = blogResponse?.items;
      let boardMembers = aboutUsResponse?.items;

      this.setState({
        contentfulItems,
        blogItems,
        boardMembers,
        mounted: true,
        locale: lang,
      });

      setTimeout(() => {
        this.setState({
          loading: false,
        });
      }, 500);
    } catch (error) {
      console.log(error);
    }
  }

  getSinglePage = (slug) => {
    let pathname;
    if (this.state.locale === "en-US") {
      pathname = slug.split("/en").pop().replace(/\//g, "");
    } else {
      pathname = slug.replace(/\//g, "");
    }
    let items = this.state.contentfulItems;

    if (pathname === "" || pathname === "en") {
      pathname = "home";
    }
    if (items) {
      const item = items.find((item) => item.fields.slug === pathname);
      if (item) {
        return item.fields;
      }

      return pathname;
    }
    return pathname;
  };

  getBlogPosts = () => {
    let blogItems = this.state.blogItems;

    if (blogItems && blogItems.length) {
      return blogItems;
    }
  };

  getBoardMembers = () => {
    let boardMembers = this.state.boardMembers;

    if (boardMembers && boardMembers.length) {
      return boardMembers;
    }
  };

  getSinglePost = (slug) => {
    let pathname;
    if (this.state.locale === "en-US") {
      pathname = slug.split("/en").pop().replace(/\//g, "");
    } else {
      pathname = slug.replace(/\//g, "");
    }
    let items = this.state.blogItems;

    if (items) {
      const item = items.find((item) => item.fields.slug === pathname);
      if (item) {
        return item;
      }

      return false;
    }
    return false;
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    if (this.state.mounted) {
      return (
        <ContentfulContext.Provider
          value={{
            ...this.state,
            getSinglePage: this.getSinglePage,
            getBlogPosts: this.getBlogPosts,
            getBoardMembers: this.getBoardMembers,
            getSinglePost: this.getSinglePost,
          }}
        >
          {this.props.children}
        </ContentfulContext.Provider>
      );
    }

    return null;
  }
}

const ContentfulConsumer = ContentfulContext.Consumer;

export { ContentfulProvider, ContentfulConsumer, ContentfulContext }