import { Component } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'

class Expandable extends Component {

    constructor() {
        super();
        this.state = {
            isOpen: false
        }
    }

    componentDidMount() {
        if(window.location.href.includes("#" + this.props.subTitle)) {
            this.setState({
                isOpen: true
            })
        }
    }
    
    render() {

        const { image, desc, buttonTitle, buttonLink, children, subTitle } = this.props;
        
        let isEnglish =  window.location.pathname.includes('/en') ? '/en' : '';
        

        return(
            <details className="rfb-expandable__item" id={subTitle} open={this.state.isOpen}>
                <summary className="rfb-expandable__item__head rfb-relative rfb-flex rfb-flex--items-center rfb-flex--space-between rfb-parag rfb-poppins-bold rfb-p-40">
                    <div className="rfb-col-11 rfb-flex rfb-flex--items-center">
                        <div className="rfb-expandable__item__head__icon">
                            <img src={ image } alt="Service"/>
                        </div>
                        <h3 className="rfb-expandable__description rfb-pl-42 rfb-expandable__item__head__title rfb-font-primary-bold">{ children }</h3>
                    </div>
                    <i className="rfb-expandable__item__icon">
                        <svg className="rfb-expandable__item__icon__arrow" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 27.598 16.213">
                            <g transform="translate(27.598 -101.478) rotate(90)">
                            <g transform="translate(101.478)">
                                <path d="M117.251,12.722,104.97.441a1.513,1.513,0,0,0-2.135,0l-.9.9a1.512,1.512,0,0,0,0,2.135l10.313,10.313L101.919,24.118a1.513,1.513,0,0,0,0,2.135l.9.9a1.513,1.513,0,0,0,2.135,0l12.293-12.292a1.524,1.524,0,0,0,0-2.143Z" transform="translate(-101.478 0)"/>
                            </g>
                            </g>
                        </svg>
                    </i>
                </summary>
                <p className="rfb-expandable__item__content rfb-parag rfb-mb-32">
                    { desc }
                </p>
                {
                    (buttonTitle) ? 
                    <Link to={ isEnglish + buttonLink} primary className="rfb-button rfb-ls-16-negative rfb-button--primary gsap-timeline rfb-font-primary-bold rfb-mb-40 rfb-mb-xs-32">{ buttonTitle }</Link>
                    : null
                }
            </details>
        )
    }
}

Expandable.propTypes = {
    desc: PropTypes.string,
    image: PropTypes.string,
    buttonTitle: PropTypes.string,
    buttonLink: PropTypes.string
}

Expandable.defaultProps = {
    desc: null,
    image: null,
    buttonTitle: null,
    buttonLink: null
};

export default Expandable;