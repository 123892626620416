import { Component } from 'react';
import Image404 from '../images/404.svg';
import { Link } from 'react-router-dom';

class ErrorTemplate extends Component {
    render() {
        return(
            <div className="rfb-404 rfb-comp rfb-comp--padding-half rfb-text-center">
                <div className="rfb-container">
                    <h3 className="rfb-404__title rfb-title-primary rfb-color-third rfb-font-primary-bold">404 page not found.</h3>
                    <picture className="rfb-404__picture">
                        <source srcSet={ Image404 }/>
                        <img className="rfb-w-80 rfb-sm-w-100 rfb-mb-80 rfb-mt-80" loading="lazy" src={ Image404 } alt="Error 404"  />
                    </picture>
                    <h3 className="rfb-404__message rfb-title-primary rfb-color-third rfb-w-60 rfb-sm-w-100 rfb-m-auto rfb-mb-64">
                        The page that you are looking for doesn't
                        exist or an other error occurred.</h3>
                    <Link to="/home" className="rfb-button rfb-ls-16-negative rfb-button--primary rfb-font-primary-bold">
                        Go to homepage
                    </Link>
                </div>
            </div>
        );
    }
}

export default ErrorTemplate;