import { Component } from "react";
import Observer from '@researchgate/react-intersection-observer';
import { tweenMaxAnimateOnce, loadImage } from '../../utility/timeline';
import Mailchimp from 'react-mailchimp-form';

const handleIntersection = (event, unobserve) => {
    if(event.isIntersecting) {
        tweenMaxAnimateOnce(event.target)
        loadImage(event.target);
        unobserve();
    }
}
class Footer extends Component {
    render() {
        const options = {
            onChange: handleIntersection,
            rootMargin: '0% 0% -25%'
        };

        let isEnglish =  window.location.pathname.includes('/en') ? true : "";

        return(
            <Observer {...options}>
                <footer className="rfb-footer rfb-comp">
                    <div className="rfb-container">
                        <div className="rfb-flex rfb-flex--space-between rfb-mb-90 rfb-flex-md--wrap rfb-mb-xs-30">
                            <p className="rfb-color-white rfb-w-md-50 rfb-mb-md-48 rfb-w-xs-100 rfb-mb-xs-30 gsap-timeline">
                                Raiffeisen Insurance Broker <br/>
                                Str: Robert Doll 99, <br/>
                                Prishtina 10000, Kosovo<br/>
                            </p>
                            <ul className="rfb-footer__phone rfb-color-white rfb-pr-16 rfb-pl-16 rfb-pl-xs-0 rfb-w-md-50 rfb-w-xs-100 rfb-mb-xs-30 gsap-timeline">
                                <li>Tel:  <a className="rfb-color-white rfb-color-white--hover" href="tel:+38138222222337">+383(0)38 222 222 337</a></li>
                                <li>Email:  <a className="rfb-color-white rfb-color-white--hover" href="mailto:info.broker@raiffeisen-kosovo.com">info.broker@raiffeisen-kosovo.com</a></li>
                            </ul>
                            <Mailchimp
                                action='https://raiffeisen-kosovo.us1.list-manage.com/subscribe/post?u=0b13aca828815ab31751bb391&amp;id=86cd610ae0' 
                                
                                fields={[
                                    {
                                        name: 'EMAIL',
                                        placeholder: 'Subscribe to our newsletter',
                                        type: 'email',
                                        required: true
                                    },
                                ]}
                                messages = {
                                    {
                                        sending: "Sending...",
                                        success: "Thank you for subscribing!",
                                        error: "An unexpected internal error has occurred.",
                                        empty: "You must write an e-mail.",
                                        duplicate: "Too many subscribe attempts for this email address",
                                        button: ""
                                    }
                                }
                                className='rfb-footer__subscribe rfb-flex rfb-footer__input-holder validate gsap-timeline'
                            />
                        </div>
                        <div className="rfb-flex rfb-flex--items-end rfb-flex--space-between rfb-flex--wrap gsap-timeline">
                            <ul className="rfb-footer__links-list rfb-flex rfb-flex--items-center rfb-w-sm-100 rfb-mb-sm-20">
                                <li><a href="https://www.raiffeisen-kosovo.com/shq/rreth-nesh/banka-raiffeisen/menaxhimi-i-ankesave/" target="_blank" rel="noreferrer" className="rfb-footer__button rfb-d-inline-block rfb-button--primary rfb-font-primary-medium ">{isEnglish ? `Complaints` : `Ankesat`}</a></li>
                            </ul>
                            <p className="rfb-text-right rfb-w-sm-100 rfb-text-sm-left">
                                © Copyright 2020
                                All rights are reserved, Raiffeisen Brokers
                            </p>
                        </div>
                    </div>
                </footer>
            </Observer>
        );
    }
}

export default Footer;