import { Component } from "react";
import PropTypes from 'prop-types';
import { Parallax as ParallaxBG } from "react-parallax";
import Swiper from 'swiper/swiper-bundle';
import Observer from '@researchgate/react-intersection-observer';
import { tweenMaxAnimateOnce, loadImage } from '../../utility/timeline';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import '../../swiper.min.css';

const handleIntersection = (event, unobserve) => {
    if(event.isIntersecting) {
        tweenMaxAnimateOnce(event.target)
        loadImage(event.target);
        unobserve();
    }
}
class TAIBSlider extends Component {
    componentDidMount() {
        new Swiper('.rfb-taib-swiper-container', {
            slidesPerView: 1,
            auto: true,
            spaceBetween: 100,
            loop: true,
            autoplay: {
                delay: 9000,
                disableOnInteraction: false,
            },
            pagination: {
                el: '.rfb-taib-swiper-pagination',
                clickable: true,
            },
            controller: {
                inverse: true,
            }
        })
    }
    render() {
        const { image, data } = this.props;

        const options = {
            onChange: handleIntersection,
            rootMargin: '0% 0% -25%'
        };

        return(
            <Observer {...options}>
                <div className="rfb-taib-slider rfb-comp rfb-comp--padding">
                    <div className="rfb-taib-slider__picture rfb-w-100 rfb-absolute rfb-img-h-100 rfb-t-0">
                        <ParallaxBG bgImage={ image } strength={250}></ParallaxBG>
                    </div>
                    <div className="rfb-container rfb-flex rfb-flex--justify-end">
                        <div className="rfb-bg--black rfb-w-50 rfb-sm-w-100 rfb-taib-slider__holder rfb-comp--dark">
                            <div className="swiper-container rfb-taib-swiper-container rfb-bg--black">
                                <div className="swiper-wrapper">
                                {
                                    data.map(({fields}, index) => {
                                        return  <div key={ index } className="swiper-slide rfb-color-third rfb-ls-16-negative rfb-bg-black gsap-timeline">
                                                    <div className="rfb-color-third rfb-ls-16-negative">
                                                        <h2 className="rfb-title-primary rfb-font-primary-bold rfb-mb-32 rfb-mb-sm-16 rfb-color-white">
                                                            { fields.title }
                                                        </h2>
                                                        <div className="rfb-taib-slider__parag rfb-parag">
                                                            <p>{ documentToReactComponents(fields.description) }</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                    })
                                }
                                </div>
                                <div className="rfb-taib-swiper-pagination"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Observer>
        );
    }
}

TAIBSlider.propTypes = {
    image: PropTypes.string,
    data: PropTypes.array
}

TAIBSlider.defaultProps = {
    image: null,
    data: null
};


export default TAIBSlider;