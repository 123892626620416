import React, { Component } from "react";
import CookieConsent from "react-cookie-consent";

class CookieBanner extends Component {
  render() {
    return (
      <CookieConsent
        location="bottom"
        buttonText="Pranoj!"
        cookieName="RIBK"
        containerClasses="rfb-flex rfb-flex--items-center"
        buttonClasses="rfb-button--primary rfb-link"
        buttonWrapperClasses="rfb-my-auto"
        expires={365}
        overlay={true}
      >
        {this.props.description}
        <a
          href={this.props.href}
          rel="noreferrer"
          className="rfb-color-primary"
          target="_blank"
        >
          {this.props.link}
        </a>
      </CookieConsent>
    );
  }
}

export default CookieBanner;
