import { Component } from 'react';
import PropTypes from 'prop-types';
import Observer from '@researchgate/react-intersection-observer';
import { tweenMaxAnimateOnce, loadImage } from '../../utility/timeline';

const handleIntersection = (event, unobserve) => {
    if(event.isIntersecting) {
        tweenMaxAnimateOnce(event.target)
        loadImage(event.target);
        unobserve();
    }
}
class Statistic extends Component { 
    render() {
        const { title, children } = this.props;

        const options = {
            onChange: handleIntersection,
            rootMargin: '0% 0% -25%'
        };

        return(
            <Observer {...options}>
                <div className="rfb-taic__statistics rfb-flex rfb-flex--items-start rfb-flex-xs--items-center rfb-flex-sm--direction-column rfb-mr-sm-20 rfb-w-xs-100 rfb-mb-xs-0 gsap-timeline">
                    <span className="rfb-taic__number rfb-font-primary-bold rfb-color-white rfb-mr-32 rfb-mr-sm-16 rfb-ls-16-negative rfb-mr-xs-0">{ children }</span>
                    <span className="rfb-taic__label rfb-font-primary-bold rfb-color-white rfb-mt-16 rfb-ls-16-negative">{ title }</span>
                </div>
            </Observer>
        );
    }
}

Statistic.propTypes = {
    title: PropTypes.string
}

Statistic.defaultProps = {
    title: ''
};

export default Statistic;