
const ImageProcess = (imagePath, width, height, quality) => {
    const formatImage = (ifBrowserIsSafari()) ? 'jpg' : 'webp';
    return imagePath.fields.file.url + '?w=' + width + '&h=' + height + '&fm=' + formatImage + '&q=' + quality;
}

const ifBrowserIsSafari = () => {
    var isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') === -1 &&
    navigator.userAgent.indexOf('FxiOS') === -1;
    return isSafari;
}

export default ImageProcess;