import React, { Component } from 'react';
import Partners from '../components/Partners';
import Footer from '../components/Footer';
import { ContentfulContext } from "../contexts/context";

class FooterTemplate extends Component {

    static contextType = ContentfulContext;
    render() {
        
        const { getSinglePage } = this.context;
        const value = getSinglePage('/footer');
        const PartnersDetail = value.sections[0].fields;

        return(
            <React.Fragment>
                <Partners data={ PartnersDetail.items }>{ PartnersDetail.title }</Partners>
                <Footer></Footer>
            </React.Fragment>
        )
    }
}

export default FooterTemplate;