import React, { Component } from "react"
import FTI from '../components/FTI';
import Map from '../components/Map';
import { GOOGLE_MAP_API_KEY, GOOGLE_MAP_CENTER } from '../constants/Config';
import Form from '../components/Form';
import { Helmet } from 'react-helmet';
import { SITE_TITLE } from '../constants/Config';
import { ContentfulContext } from '../contexts/context';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';


class ContactTemplate extends Component {
    constructor(props) {
      super(props);
      this.state = {
          slug: this.props.match.url
      }
    }
    componentDidMount () {
        const hashId = window.location.hash.slice(1)
        const elem = document.getElementById(hashId)
        if (elem) {
            elem.scrollIntoView()
        } else {
            window.scrollTo(0, 0);
        }
    }

    static contextType = ContentfulContext;

    render() {
        
        const { getSinglePage } = this.context;
        const value = getSinglePage(this.state.slug);
        const contactData = value.sections[0].fields;
        const mapData = value.sections[1].fields;
        const ftiData = value.sections[2].fields;

        return(
            <React.Fragment>
                <Helmet>
                    <title>{ `${ SITE_TITLE } | ${ value?.page }` }</title>
                    <link rel="canonical" href={ window.location.href }></link>
                    <meta name="description" content={ value?.seoDescription } />
                    <meta property="og:title" content={ `${ SITE_TITLE } | ${ value?.page }` } />
                    <meta property="og:description" content={ value?.seoDescription } />
                    <meta property="og:url" content={ window.location.href } />
                    <meta property="og:image" content={ value?.seoImage.fields.file.url } />
                    <meta property="og:image:secure_url" content={ value?.seoImage.fields.file.url } />
                    <meta property="og:image:width" content="1820" />
                    <meta property="og:image:height" content="984" />                            
                </Helmet>  
                <Form data={contactData.items} checkboxTitle={contactData.subTitle} checkboxLabel={documentToReactComponents(contactData.description)} formTitle={contactData.title}></Form>
                <Map mapKey={ GOOGLE_MAP_API_KEY } zoom={ 14 } center={ GOOGLE_MAP_CENTER }>{ mapData.items }</Map>
                <FTI image={ ftiData.image.fields.file.url } buttonUrl={ ftiData.buttonLink }
                linkTitle={ftiData.buttonTitle}>{ ftiData.title }</FTI>
            </React.Fragment>
        )
    }
}

export default ContactTemplate;