import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Parallax as ParallaxBG } from "react-parallax";
import { tweenMaxAnimateOnce, loadImage } from '../../utility/timeline';
import Observer from '@researchgate/react-intersection-observer';

const handleIntersection = (event, unobserve) => {
    if(event.isIntersecting) {
        tweenMaxAnimateOnce(event.target)
        loadImage(event.target);
        unobserve();
    }
}

class FTI extends Component { 
    render() {
        const options = {
            onChange: handleIntersection,
            rootMargin: '0% 0% -25%'
        };

        const { large, primary, image, subtitle, buttonTitle, buttonUrl, linkTitle, linkUrl, leftLine, children } = this.props;

        const headLineClass = classNames({'rfb-fti__title rfb-color-white  rfb-font-primary-bold  rfb-ls-16-negative': primary});
        const leftLineClass = classNames({'rfb-title-border': leftLine});
        const linkTitleClass = classNames({'rfb-mb-16': linkTitle, 'rfb-mb-28': !linkTitle});
        const largeClass = classNames({'rfb-fti--large': large, 'rfb-comp--padding-half rfb-pt-sm-0 rfb-flex rfb-flex--items-center rfb-d-sm-block': !large})
        
        return(
            <Observer {...options}>
                <div className={`rfb-fti ${ largeClass } rfb-comp`}>
                    <div className="rfb-fti__picture rfb-absolute rfb-sm-relative rfb-window-height gsap-timeline">
                        <ParallaxBG bgImage={ image } strength={150} blur={{ min: 3, max: -5 }}></ParallaxBG>
                    </div>
                    <div className="rfb-container">
                        <div className={`rfb-relative ${ leftLineClass } gsap-timeline rfb-mt-sm-30 rfb-max-w-50 rfb-pr-16`}>
                            {
                                (subtitle) ? 
                                    <p className="rfb-title-quote rfb-mb-16 rfb-color-white rfb-ls-16-negative gsap-timeline">{ subtitle }</p> 
                                    : null
                            }
                            {
                                (primary) ? 
                                    <h1 className={ headLineClass }>{ children }</h1> 
                                    : <h3 className={`rfb-fti__title gsap-timeline rfb-font-primary-bold ${ linkTitleClass } rfb-color-white rfb-ls-16-negative`}>{ children }</h3>
                            }
                            {
                                (buttonTitle) ? 
                                <Link to={buttonUrl} primary className="rfb-button rfb-ls-16-negative rfb-button--primary gsap-timeline rfb-font-primary-bold rfb-mr-32 rfb-mb-xs-16">{ buttonTitle }</Link>
                                : null
                            }
                            {
                                (linkTitle) ?
                                <a href={ window.location.origin + '/' + buttonUrl } className="rfb-fti-link gsap-timeline rfb-ls-16-negative">{ linkTitle }</a>
                                : null
                            }
                        </div>
                    </div>
                </div>
            </Observer>
        )
    }
}

FTI.propTypes = {
    large: PropTypes.bool,
    primary: PropTypes.bool,
    image: PropTypes.string,
    subtitle: PropTypes.string,
    buttonTitle: PropTypes.string,
    linkTitle: PropTypes.string,
    linkUrl: PropTypes.string,
    leftLine: PropTypes.bool,
    buttonUrl: PropTypes.string
}

FTI.defaultProps = {
    large: false,
    primary: false,
    image: null,
    description: null,
    buttonTitle: null,
    linkTitle: null,
    linkUrl: null,
    leftLine: false,
    buttonUrl: null
};

export default FTI;