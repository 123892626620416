import { Component } from "react";
import PropTypes from 'prop-types';
import Observer from '@researchgate/react-intersection-observer';
import { tweenMaxAnimateOnce, loadImage } from '../../utility/timeline';
import Swiper from 'swiper/swiper-bundle';
import { Link } from 'react-router-dom';

import '../../swiper.min.css';

const handleIntersection = (event, unobserve) => {
    if(event.isIntersecting) {
        tweenMaxAnimateOnce(event.target)
        loadImage(event.target);
        unobserve();
    }
}

class Partners extends Component {
    componentDidMount() {
        new Swiper('.rfb-partners-slider', {
            slidesPerView: 5,
            spaceBetween: 50,
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                576: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                992: {
                    slidesPerView: 5,
                    spaceBetween: 40,
                },
                1200: {
                    spaceBetween: 50,
                },
            }
        });
    }

    render() {
        const options = {
            onChange: handleIntersection,
            rootMargin: '0% 0% -25%'
        };
        
        const { data, children } = this.props;
        if(!data) {return;}
        return(
            <Observer {...options}>
                <div className="rfb-partners rfb-comp rfb-comp--padding">
                    <div className="rfb-container">
                        <div className="rfb-row">
                            <div className="rfb-col-12">
                                <h2 className="rfb-title-secondary rfb-font-primary-bold rfb-mb-80 rfb-text-sm-center gsap-timeline">{ children }</h2>
                                <div className="rfb-partners__list rfb-flex rfb-flex--wrap rfb-flex--space-between">
                                    <div className="rfb-partners-slider swiper-container rfb-w-100">
                                        <div className="swiper-wrapper">
                                            {
                                                data.map(({fields}, index) => {
                                                    return  <div key={ index } className="swiper-slide">
                                                                <div className="rfb-flex rfb-flex--items-center rfb-flex--justify-center rfb-sm-w-100 rfb-partners__list-item rfb-mb-md-32 gsap-timeline">
                                                                    {
                                                                        fields.buttonLink ?
                                                                        <a href={ fields.buttonLink ? fields.buttonLink : "/" } target="_blank" rel="noreferrer">
                                                                            <img className="rfb-partners__list-img" src={ fields.image.fields.file.url } alt="partner 1"/>
                                                                        </a> :
                                                                        <>
                                                                            <img className="rfb-partners__list-img" src={ fields.image.fields.file.url } alt="partner 1"/>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Observer>
        );
    }
}

Partners.propTypes = {
    data: PropTypes.array
}

Partners.defaultProps = {
    data: null
};


export default Partners;