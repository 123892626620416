import { Component } from 'react';
import PropTypes from 'prop-types';
import Observer from '@researchgate/react-intersection-observer';
import { tweenMaxAnimateOnce, loadImage } from '../../utility/timeline';
import { Parallax as ParallaxBG } from "react-parallax";
import { Link } from 'react-router-dom';

const handleIntersection = (event, unobserve) => {
    if(event.isIntersecting) {
        tweenMaxAnimateOnce(event.target)
        loadImage(event.target);
        unobserve();
    }
}
class TAIB extends Component { 

    render() {
        const options = {
            onChange: handleIntersection,
            rootMargin: '0% 0% -25%'
        };
        
        const { image, description, buttonTitle, buttonLink, children } = this.props;
        
        return(
            <Observer {...options}>
                <div ref={mount => { this.mount = mount}} className="rfb-taib rfb-comp rfb-comp--dark rfb-comp--padding">
                    <ParallaxBG bgImage={ image } strength={250}></ParallaxBG>
                    <div className="rfb-container rfb-comp--padding">
                        <div className="rfb-flex rfb-flex--justify-end">
                            <div className="rfb-w-50 rfb-sm-w-100">
                                <div className="rfb-taib__wrapper rfb-comp--dark rfb-bg--black rfb-p-sm-25 rfb-pt-72 rfb-pb-80 rfb-pr-42 rfb-pl-42 rfb-relative rfb-zindex-1">
                                    <h3 className="rfb-taib__title gsap-timeline rfb-title-primary rfb-font-primary-bold rfb-mb-40 rfb-mb-sm rfb-mt-sm-16 rfb-ls-16-negative">
                                        { children }
                                    </h3>
                                    <p className="rfb-taib__parag gsap-timeline rfb-mb-56 rfb-mb-sm rfb-ls-16-negative">
                                        { description }
                                    </p>
                                    <Link to={ buttonLink } className="rfb-button rfb-button--primary rfb-font-primary-bold gsap-timeline rfb-ls-16-negative rfb-d-inline-block rfb-mb-sm-20">{ buttonTitle }</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Observer>
        )
    }
}

TAIB.propTypes = {
    image: PropTypes.string,
    description: PropTypes.string,
    buttonLink: PropTypes.string,
    buttonTitle: PropTypes.string
}

TAIB.defaultProps = {
    image: null,
    description: null,
    buttonLink: '',
    buttonTitle: ''
};

export default TAIB;