import { Component } from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { tweenMaxAnimateOnce, loadImage } from '../../utility/timeline';
import Observer from '@researchgate/react-intersection-observer';

const handleIntersection = (event, unobserve) => {
    if(event.isIntersecting) {
        tweenMaxAnimateOnce(event.target)
        loadImage(event.target);
        unobserve();
    }
}

class Hero extends Component {
    render() {
        const options = {
            onChange: handleIntersection,
            rootMargin: '0% 0% -25%'
        };

        const { subtitle, dark, fullHeight, paragMargins, dflex, image, description, children } = this.props;

        const darkClass = classNames({'rfb-comp--grey': !dark, 'rfb-comp--dark': dark});
        const fullHeightClass = classNames({'': !fullHeight, 'rfb-height-vh': fullHeight});
        const paragMarginsClass = classNames({'rfb-pr-250': !paragMargins, 'rfb-mb-72 rfb-mb-sm-20': paragMargins});
        const dflexClass = classNames({'': !dflex, 'rfb-flex  rfb-flex--wrap rfb-flex--content-center': dflex});

        return(
            <Observer {...options}>
                <div className={`rfb-hero rfb-comp ${ darkClass } rfb-hero--padding rfb-zindex-1 ${fullHeightClass}`}>
                    <picture className="rfb-hero__picture gsap-timeline rfb-hero--picture-right rfb-hidden-sm rfb-d-none rfb-d-lg-block">
                        <source srcSet={ image } className={`${fullHeightClass}`}/>
                    </picture>
                    <div className={`rfb-container rfb-container--inner rfb-window-height ${dflexClass}`}>
                        {
                            (subtitle) ?
                                <span className="rfb-title-quote rfb-w-100 rfb-mb-16 gsap-timeline">{ subtitle }</span>
                                : null
                        }
                        <h2 className="rfb-title-secondary rfb-font-primary-bold rfb-mb-sm-16 rfb-max-w-80 rfb-mb-40 gsap-timeline">
                            { children }
                        </h2>
                        <div className={`rfb-parag rfb-parag--margins ${paragMarginsClass} gsap-timeline`}>
                            <p>
                                { description }
                            </p>
                        </div>
                    </div>
                </div>
            </Observer>
        );
    }
}

Hero.propTypes = {
    dark: PropTypes.bool,
    image: PropTypes.string,
    description: PropTypes.string,
    subtitle: PropTypes.string,
    fullHeight: PropTypes.bool, 
    paragMargins: PropTypes.bool, 
    dflex: PropTypes.bool
}

Hero.defaultProps = {
    dark: false,
    image: null,
    description: null,
    subtitle: null,
    fullHeight: false, 
    paragMargins: false, 
    dflex: false
};

export default Hero;