import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class Card extends Component { 
    render() {
        const {image, url, children} = this.props;

        const isEnglish = window.location.pathname.includes('/en') ? '/en/' : '/';

        return (
          <div className="rfb-aspect-ratio-1x1 rfb-flex-md--wrap rfb-cards__item  gsap-timeline">
            {url ? (
              <Link
                to={`${isEnglish}news/${url}`}
                style={{ backgroundImage: `url(${image})` }}
                className="rfb-flex rfb-flex--direction-column rfb-flex--justify-end rfb-aspect-ratio-1x1__img rfb-cards__item-btn"
              >
                <div className="rfb-color-white rfb-relative rfb-cards__item-title">
                  <p className="rfb-title-fourth rfb-font-primary-bold rfb-mt-16 rfb-ellipsis rfb-ellipsis--3 rfb-min-h-90">
                    {children}
                  </p>
                </div>
              </Link>
            ) : (
              <div
                style={{ backgroundImage: `url(${image})` }}
                className="rfb-flex rfb-flex--direction-column rfb-flex--justify-end rfb-aspect-ratio-1x1__img rfb-cards__item-btn"
              >
                <div className="rfb-color-white rfb-relative rfb-cards__item-title">
                  <p className="rfb-title-fourth rfb-font-primary-bold rfb-mt-16 rfb-ellipsis rfb-ellipsis--3 rfb-min-h-90">
                    {children}
                  </p>
                </div>
              </div>
            )}
          </div>
        );
    }
}

Card.propTypes = {
    image: PropTypes.string.isRequired,
}

Card.defaultProps = {
    primary: null,
    secondary: ''
};  

export default Card;