import { Component } from "react";
import PropTypes from 'prop-types';
import SliderCard from '../SliderCard';
import Swiper from 'swiper/swiper-bundle';
import Observer from '@researchgate/react-intersection-observer';
import { tweenMaxAnimateOnce, loadImage } from '../../utility/timeline';

import '../../swiper.min.css';

const handleIntersection = (event, unobserve) => {
    if(event.isIntersecting) {
        tweenMaxAnimateOnce(event.target)
        loadImage(event.target);
        unobserve();
    }
}

class SliderCards extends Component {
    componentDidMount() {
        this.sideContainer();
        this.initSwiper();
        window.addEventListener('resize', this.sideContainer);

        var tabInput = document.getElementsByClassName('rfb-tabs__checkbox');
        for (var i = 0; i < tabInput.length; i++) {
            tabInput[i].addEventListener('click', this.initSwiper);
        }

    }

    initSwiper = () => {
        new Swiper('.rfb-slider-cards--swiper-container', {
            slidesPerView: 4.7,
            spaceBetween: 50,
            loop: true,
            loopedSlides: 50,
            navigation: {
                nextEl: '.rfb-slider-cards--swiper-button-next',
                prevEl: '.rfb-slider-cards--swiper-button-prev',
            },
            breakpoints: {
            320: {
                slidesPerView: 1.7,
                spaceBetween: 20,
            },
            576: {
                slidesPerView: 2.7,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 3.7,
                spaceBetween: 30,
            },
            992: {
                slidesPerView: 4.7,
                spaceBetween: 40,
            },
            1200: {
                spaceBetween: 50,
            },
            }
        });
    }

    sideContainer = () => {
        const windowWidth = window.innerWidth;
        const containerWidth = document.querySelector('.rfb-container').offsetWidth;
        const sideContainer = (windowWidth - containerWidth + 30) / 2;
        const leftContainer = document.querySelector('.rfb-left-container');
        const rightContainer = document.querySelector('.rfb-right-container');

        leftContainer && (leftContainer.style.paddingLeft = sideContainer + 'px');
        rightContainer && (rightContainer.style.paddingRight = sideContainer + 'px');
    }

    render() {
        const { data, children } = this.props;
        const options = {
            onChange: handleIntersection,
            rootMargin: '0% 0% -25%'
        };

        let pathname = window.location.pathname;
        let isEnglish = pathname.includes('/en') ? '/en' : '/';
    
        return(
            <Observer {...options}>
                <div className="rfb-slider-cards rfb-comp rfb-comp--dark rfb-comp--padding">
                    <div className="rfb-container">
                        <div className="rfb-row">
                            <div className="rfb-col rfb-col-5">
                                <h3 className="rfb-title-primary rfb-font-primary-bold rfb-mb-sm-40 gsap-timeline">{ children }</h3>
                            </div>
                        </div>
                    </div>
                    <div className="rfb-tabs rfb-mt-60-negative rfb-mt-sm-0 rfb-left-container">
                    {
                        data.map(({fields}, index) => {
                            return <input key={ ++index } className={`rfb-tabs__checkbox rfb-${fields.title}`} id={`tab-${ ++index }`} type="radio" name="tabs" defaultChecked={ (index === 1) }/>
                        })
                    }                       
                        <div className="rfb-tabs__buttons rfb-right-container rfb-flex rfb-flex--items-center rfb-flex--justify-end rfb-flex-sm--justify-start rfb-mb-100 rfb-mb-sm-72 rfb-mb-xs-50 gsap-timeline">
                        {
                            data.map(({fields}, index) => {
                                return <label htmlFor={`tab-${ ++index }`} key={ index } className={`rfb-tabs__item rfb-tabs__item--light rfb-relative rfb-font-primary-medium rfb-pr-32`}>
                                        { fields.title }</label>
                            })
                        }                    
                        </div>
                        <div className="rfb-tabs__content">
                        {
                            data.map(({ fields }, index) => {
                                return  <div key={ index } className="rfb-tabs__content__tab">
                                            <div className={`swiper-container rfb-slider-cards--swiper-container gsap-timeline`}>
                                                <div className="swiper-wrapper">
                                                    {
                                                        fields.items.map(({ fields }, itemIndex) => {
                                                            return  <SliderCard key={ itemIndex } image={ fields.image.fields.file.url } subTitle={fields.subTitle} buttonTitle={fields.buttonTitle} buttonLink={ `${isEnglish}` + fields.buttonLink }>{ fields.title }</SliderCard>
                                                        })
                                                    }
                                                </div>
                                                <div className={`swiper-button-next rfb-slider-cards--swiper-button-next gsap-timeline`}>
                                                    <svg width="43px" height="43px" viewBox="0 0 28.4 15.001">
                                                        <path fill="#FFF400" d="M1034.53,2683.5v2.693h20.84v6.12l7.56-7.506-7.56-7.495v6.188Z" transform="translate(-1034.53 -2677.312)"/>
                                                    </svg>
                                                </div>
                                                <div className={`swiper-button-prev rfb-slider-cards--swiper-button-prev gsap-timeline`}>
                                                    <svg width="43px" height="43px" viewBox="0 0 28.4 15.001">
                                                        <path fill="#FFF400" d="M1062.93,2683.5v2.693h-20.84v6.12l-7.56-7.506,7.56-7.495v6.188Z" transform="translate(-1034.53 -2677.312)"/>
                                                    </svg>                                  
                                                </div>
                                            </div>
                                        </div>
                            })
                        }
                        </div>
                    </div>
                </div>
            </Observer>
        )
    }
}

SliderCards.propTypes = {
    data: PropTypes.array,
}

SliderCards.defaultProps = {
    data: null,
};

export default SliderCards;