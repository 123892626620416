import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import { SITE_TITLE } from '../constants/Config';
import { ContentfulContext } from '../contexts/context';
import Qs from 'qs';
import Cards from '../components/Cards';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

class SearchTemplate extends Component {
    constructor(props) {
      super(props);
      this.state = {
          slug: this.props.match.url
      }

      this.searchKeyword = Qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).keyword;
      this.blogsToShow = [];

    }
    componentWillReceiveProps(nextProps){	
        if (nextProps.slug !== this.props.slug) {	
            this.setState({ slug: nextProps.slug })	
        }	
    }

    static contextType = ContentfulContext;

    render() {

        const { getSinglePost, getBlogPosts } = this.context;	
        const blogs = getBlogPosts();

        this.blogsToShow = blogs.filter(blog => {
            const blogTitle = blog.fields.title.toLowerCase();
            const blogStringContent = documentToHtmlString(getSinglePost(blog.fields.slug).fields.content).replace(/<[^>]+>/g, '').toLowerCase();
            const searchToLower = this.searchKeyword.toLowerCase();
            if (blogTitle.includes(searchToLower) || blogStringContent.includes(searchToLower)) {
                return blog;
            }
        }); 

        return(
            <React.Fragment>
                <Helmet>
                    <title>{ `${ SITE_TITLE } | Search` }</title>
                    <link rel="canonical" href={ window.location.href }></link>
                    <meta name="description" content='' />
                    <meta property="og:title" content={ `${ SITE_TITLE }` } />
                    <meta property="og:description" content='' />
                    <meta property="og:url" content={ window.location.href } />
                    <meta property="og:image" content='' />
                    <meta property="og:image:secure_url" content='' />
                    <meta property="og:image:width" content="1820" />
                    <meta property="og:image:height" content="984" />                              
                </Helmet>
                <Cards data={ this.blogsToShow }>{ this.blogsToShow.length > 0 ? 'Results for: ' + this.searchKeyword : 'Nothing found found for: ' + this.searchKeyword }</Cards>	
            </React.Fragment>
        )
    }
}

export default SearchTemplate;