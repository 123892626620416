import { Component } from "react";
import { Link } from 'react-router-dom';

class SliderCard extends Component {
    render() {
        const {image, buttonTitle, buttonLink, children} = this.props;
        return(
            <div className="swiper-slide">
                <Link to={ buttonLink } className="rfb-slider-cards__box rfb-window-height rfb-flex rfb-flex--direction-column rfb-flex--space-between">
                    <figure className="rfb-slider-cards__picture rfb-d-inline-block rfb-mb-100">
                        <source srcSet={ image } />
                        <img className="rfb-max-h-100 rfb-object-contain" loading="lazy" src={ image } alt=" FTI dots" />
                    </figure>
                    <div>
                        <h3 className="rfb-slider-cards__box__title rfb-ellipsis rfb-ellipsis--2 rfb-color-white rfb-font-primary-medium rfb-mb-12">{children}</h3>
                        <span className="rfb-slider-cards__box__more rfb-color-primary">{ buttonTitle }</span>
                    </div>
                </Link>
            </div>
        )
    }
}

export default SliderCard;