import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TAIFBox from '../TAIFBox';

class TAIFSlider extends Component { 
    render() {
        const {buttonTitle, buttonLink, image, forms, children} = this.props;

        return (
            <div className="swiper-slide rfb-taif__swiper--slide">
                <div className="rfb-relative rfb-flex rfb-flex--items-center rfb-window-height rfb-taif-flex-sm--align-start">
                    <picture className="rfb-taif-picture rfb-absolute rfb-w-100 rfb-window-height">
                        <source srcSet={ image } />
                    </picture>
                    <div className="rfb-container">
                        <div className="rfb-relative rfb-max-w-50 rfb-taif__slider--responsive">
                            <h2 className="rfb-taif-slider-title rfb-font-primary-bold rfb-mb-40 rfb-pt-sm-56 rfb-color-white rfb-ls-16-negative gsap-timeline">
                                { children }
                            </h2>
                            {
                                (buttonTitle) ?
                                    <Link to={ buttonLink ? buttonLink : "/" } className="rfb-taif-button-explore rfb-button rfb-button--primary rfb-bg-white rfb-font-primary-bold rfb-ls-16-negative rfb-d-inline-block rfb-mb-sm-20 gsap-timeline">{ buttonTitle }</Link>
                                : null
                            }
                        </div>
                    </div>
                </div>
                <TAIFBox inputLabel={ forms.fields.subTitle } formCheckboxes={ forms.fields.items }>{ forms.fields.title }</TAIFBox>
            </div>
        );
    }
}

TAIFSlider.propTypes = {
    buttonTitle: PropTypes.string,
    buttonLink: PropTypes.string,
    image: PropTypes.string,
    forms: PropTypes.object
}

TAIFSlider.defaultProps = {
    buttonTitle: null,
    buttonLink: null,
    image: null,
    forms: null
};  

export default TAIFSlider;