import React, { Component } from "react";
import FTI from "../components/FTI/FTI";
import Hero from "../components/Hero";
import TAIBSlider from "../components/TAIBSlider";
import { Helmet } from 'react-helmet';

import { ContentfulContext } from "../contexts/context";
import { SITE_TITLE } from '../constants/Config';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Cards from "../components/Cards";

class AboutTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: this.props.match.url,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.slug !== this.props.slug) {
      this.setState({ slug: nextProps.slug });
    }
  }

  static contextType = ContentfulContext;

  render() {
    const { getSinglePage, getBoardMembers } = this.context;
    const value = getSinglePage(this.state.slug);
    const members = getBoardMembers();
    const FTIDetail = value.sections[0].fields;
    const HeroDetail = value.sections[1].fields;
    const TAIBSliderDetail = value.sections[2].fields;
    const HeroDarkDetail = value.sections[3].fields;
    const membersDetail = value.sections[4].fields;

    return (
      <React.Fragment>
        <Helmet>
          <title>{`${SITE_TITLE} | ${value?.page}`}</title>
          <link rel="canonical" href={window.location.href}></link>
          <meta name="description" content={value?.seoDescription} />
          <meta
            property="og:title"
            content={`${SITE_TITLE} | ${value?.page}`}
          />
          <meta property="og:description" content={value?.seoDescription} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image" content={value?.seoImage.fields.file.url} />
          <meta
            property="og:image:secure_url"
            content={value?.seoImage.fields.file.url}
          />
          <meta property="og:image:width" content="1820" />
          <meta property="og:image:height" content="984" />
        </Helmet>
        <FTI
          large
          leftLine
          image={FTIDetail.image.fields.file.url}
          subtitle={FTIDetail.subTitle}
        >
          {FTIDetail.title}
        </FTI>
        <Hero
          paragMargins
          dark
          image={HeroDetail.image?.fields.file.url}
          description={documentToReactComponents(HeroDetail.description)}
        >
          {HeroDetail.title}
        </Hero>
        <Cards data={members}>{membersDetail.title}</Cards>
        <TAIBSlider
          image={TAIBSliderDetail.image.fields.file.url}
          data={TAIBSliderDetail.items}
        ></TAIBSlider>
        <Hero
          dark
          fullHeight
          dflex
          image={HeroDarkDetail.image.fields.file.url}
          subtitle={HeroDarkDetail.subTitle}
          description={documentToReactComponents(HeroDarkDetail.description)}
        >
          {HeroDarkDetail.title}
        </Hero>
      </React.Fragment>
    );
  }
}

export default AboutTemplate;